import * as React from 'react';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import * as Sentry from '@sentry/nextjs';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCountUp } from 'react-countup';
import toast from 'react-hot-toast';
import { BiLockOpen } from 'react-icons/bi';
import { FiShare } from 'react-icons/fi';
import { getLeaderboard } from 'services/client/api/getLeaderboard';
import { getUserById } from 'services/client/api/getUserById';
import { submitAppPing } from 'services/client/api/submitAppPing';
import { submitJoin } from 'services/client/api/submitJoin';
import { submitReferralClick } from 'services/client/api/submitReferralClick';
import {
  FAN_RACE,
  FORM_ID,
  LEADERBOARD_ID,
  LEADERBOARD_ID_LINK,
  REF_ID_KEY,
  REF_USERNAME_KEY,
  STORED_ID_KEY,
} from 'utils/constants';
import { faqs } from 'utils/constants';
import { transformFullNameToParts } from 'utils/transformFullNameToParts';
import Spinner from 'svg/Spinner';
import ContactForm from 'components/ContactForm';
import Countdown from 'components/Countdown';
import Faq from 'components/Faq';
import LeaderboardMeter from 'components/LeaderboardMeter';
import ModalJoin from 'components/ModalJoin';
import ModalLearnMore from 'components/ModalLearnMore';
import ModalLogin from 'components/ModalLogin';
import ModalSelectConference from 'components/ModalSelectConference';
import ModalSelectTeam from 'components/ModalSelectTeam';
import ModalShare from 'components/ModalShare';
import ModalSuccess from 'components/ModalSuccess';
import classNames from 'styles/classNames';
import { LeaderboardGridRow, ScrollbarContainer } from 'styles/elements';
import { Props } from './props';

const LEADERBOARD_POLL_INTERVAL = 5 * 60_000;
const LIVE_TEXT = 'Live';
const FAN_COUNT_TEXT = `${FAN_RACE / 1000}k`;
const howItWorks = [
  {
    title: 'Sign up',
    image: '/images/hiw-signup.svg',
    description: LIVE_TEXT,
  },
  {
    title: 'Grow fanbase',
    image: '/images/hiw-talent.svg',
    description: LIVE_TEXT,
  },
  {
    title: 'Donate',
    image: '/images/hiw-donate.svg',
    description: 'Q1 2023',
  },
];
const athleteValueProps = [
  {
    title: 'Players first',
    description: 'We empower fans to donate directly to their team for NIL. No funny business.',
    image: '/images/player-first.svg',
  },
  {
    title: 'Transparency is king',
    description:
      'It’s your donation, you will know exactly where it goes. See donation levels in real time and team distributions.',
    image: '/images/transparency.svg',
  },
  {
    title: 'Equal distributions',
    description:
      'We distribute your NIL contributions across your team’s entire roster evenly. Athletes can focus on the game.',
    image: '/images/equity.svg',
  },
  {
    title: 'Fandom at its finest',
    description: 'We’re building a super product for super fans. No more agencies.',
    image: '/images/fandom.svg',
  },
];

const leaderboardHeaders = [
  'Place',
  'Team',
  `${FAN_COUNT_TEXT} Fans`,
  'Number of Fans',
  'Total Donations',
  // 'New fans in the last hour',
];

enum LeaderboardTabs {
  Fan = 'FAN',
  Bank = 'BANK',
}

export default function Home({ teams, conferences }: Props) {
  const router = useRouter();
  const countUpRef = React.useRef(null);
  const { start, update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: 0,
    delay: 1,
    duration: 2,
    separator: ',',
  });
  const [viewer, setViewer] = React.useState(null);
  const [totalFans, setTotalFans] = React.useState(0);
  const [leaderboard, setLeaderboard] = React.useState<Record<string, number>>({});
  const [isLeaderboardFetched, setIsLeaderboardFetched] = React.useState(false);
  const [fanId, setFanId] = React.useState<number | null>(null);
  const [isIdChecked, setIsIdChecked] = React.useState(false);
  const [isCounterShowing, setIsCounterShowing] = React.useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [isJoinOpen, setIsJoinOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAthleteJoinOpen, setIsAthleteJoinOpen] = React.useState(false);
  const [isSelectTeamOpen, setIsSelectTeamOpen] = React.useState(false);
  const [isSelectConferenceOpen, setIsSelectConferenceOpen] = React.useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = React.useState(false);
  const [isLoginOpen, setIsLoginlOpen] = React.useState(false);
  const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] = React.useState(false);
  const [selectedConference, setSelectedConference] = React.useState('');
  const [selectedTeam, setSelectedTeam] = React.useState('');
  const [activeLeaderboardTab, setActiveLeaderboardTab] = React.useState(LeaderboardTabs.Fan);
  const [leaderboardSearch, setLeaderboardSearch] = React.useState('');
  const teamsWithFans = React.useMemo(() => {
    return teams
      .map((team) => {
        return {
          ...team,
          fans: leaderboard[team.id] || 0,
        };
      })
      .sort((a, b) => b.fans - a.fans);
  }, [teams, leaderboard]);
  const teamObject = React.useMemo(() => {
    if (!selectedTeam) {
      return null;
    }

    return teamsWithFans.find((team) => team.id === selectedTeam);
  }, [teamsWithFans, selectedTeam]);
  const conferenceObject = React.useMemo(() => {
    if (!selectedConference) {
      return null;
    }

    return conferences.find((conference) => conference.id === selectedConference);
  }, [conferences, selectedConference]);
  const teamsByConference = React.useMemo(() => {
    return teamsWithFans.filter((team) => {
      if (!selectedConference) {
        return true;
      }

      return team.conferenceId === selectedConference;
    });
  }, [teamsWithFans, selectedConference]);
  const leaderboardTeams = React.useMemo(
    () =>
      teamsByConference.filter((team) => {
        if (leaderboardSearch.length < 2) {
          return true;
        }

        const search = leaderboardSearch.toLowerCase();
        const teamName = team.name.toLowerCase();
        const teamMascot = team.mascot.toLowerCase();
        const teamSchool = team.school.toLowerCase();

        return (
          teamName.includes(search) || teamMascot.includes(search) || teamSchool.includes(search)
        );
      }),
    [teamsByConference, leaderboardSearch],
  );

  React.useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const newLeaderboard = await getLeaderboard();
        setLeaderboard(newLeaderboard.leaderboard || []);
        setTotalFans(newLeaderboard.totalFans || 0);
        setIsLeaderboardFetched(true);

        setTimeout(() => {
          setIsCounterShowing(true);
          update(newLeaderboard.totalFans || 0);
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    };

    const refetchLeaderboard = async () => {
      try {
        const newLeaderboard = await getLeaderboard();
        setLeaderboard(newLeaderboard.leaderboard || []);
        setTotalFans(newLeaderboard.totalFans || 0);

        setTimeout(() => {
          setIsCounterShowing(true);
          update(newLeaderboard.totalFans || 0);
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    };

    let interval = setInterval(() => {
      refetchLeaderboard();
    }, LEADERBOARD_POLL_INTERVAL);

    fetchLeaderboard();

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const checkForExistingUser = async () => {
      try {
        const storedFanId = localStorage.getItem(STORED_ID_KEY);
        if (storedFanId) {
          setFanId(parseInt(storedFanId, 10));
          const user = await getUserById(storedFanId);
          setViewer(user);
          setName(user.name);
          setSelectedTeam(user.teamId);

          submitAppPing({
            fanId: parseInt(storedFanId, 10),
            email: user?.email || '',
            team: user?.team?.name,
            pathname: '/',
            queryString: location.search,
          }).catch((error) => {
            Sentry.captureException(error);
          });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
      setIsIdChecked(true);
    };

    checkForExistingUser();
  }, []);

  React.useEffect(() => {
    if (router.isReady) {
      const { c, fan, fanId } = router.query;

      if (c && typeof c === 'string') {
        const conferenceId = conferences.find((conference) => conference.slug === c)?.id;
        setSelectedConference(conferenceId);
      }

      if (fan && typeof fan === 'string') {
        // Track to username
        try {
          localStorage.setItem(REF_USERNAME_KEY, fan);
        } catch (error) {
          Sentry.captureException(error);
        }
      } else if (fanId && typeof fanId === 'string') {
        try {
          localStorage.setItem(REF_ID_KEY, fanId);
        } catch (error) {
          Sentry.captureException(error);
        }

        try {
          submitReferralClick({
            referralFanId: parseInt(fanId, 10),
            conference: typeof c === 'string' ? c : '',
            destinationUrl: window.location.href,
            referralFanUsername: '',
          }).catch((error) => Sentry.captureException(error));
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    }
  }, [conferences, router.isReady]);

  return (
    <>
      <Head>
        <title>Arena | NIL Platform</title>
        <meta
          name="description"
          content="The NIL platform driven by fans. Compete with rival fan bases to land the best talent."
        />
      </Head>
      <div>
        <main className="overflow-x-hidden pb-20">
          <div className="relative z-50 min-h-screen bg-color-bg-primary pb-16 2xl:min-h-[92vh]">
            <header
              className={classNames(
                'relative z-10 flex flex-col items-center px-gutter-mobile lg:px-gutter',
              )}
            >
              <div className="flex h-nav-height w-full max-w-main-content items-center justify-between">
                <div className="relative z-10 shrink-0">
                  <img src="/images/logo.svg" className="h-5 md:h-auto" />
                </div>
                <div className="inline-flex h-nav-height items-center justify-end md:fixed md:left-1/2 md:-translate-x-1/2 md:justify-center">
                  <Transition
                    unmount={false}
                    show={isCounterShowing}
                    enter="transition-opacity duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="flex items-center space-x-2 md:space-x-4">
                      {/* <span className="hidden font-medium text-color-text-secondary md:block">
                        already
                      </span> */}
                      <div
                        className="text-shadow-fans bg-gradient-to-r from-[#A3F7A1] to-[#8EDBE0] bg-clip-text text-xl font-bold text-transparent md:text-[32px]"
                        ref={countUpRef}
                      >
                        0
                      </div>
                      <span className="text-sm font-medium text-color-text-secondary md:text-base">
                        fans on Arena
                      </span>
                    </div>
                  </Transition>
                </div>
                <div className="relative z-10 hidden shrink-0 items-center md:flex">
                  <Transition
                    show={isIdChecked && !fanId}
                    enter="transition-opacity duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <button
                      className="mr-8 font-medium text-[#22D3EE]"
                      type="button"
                      onClick={() => setIsLoginlOpen(true)}
                    >
                      Log in
                    </button>
                  </Transition>
                  <div>
                    <a className="button-small-hollow" href={LEADERBOARD_ID_LINK}>
                      View Leaderboard
                    </a>
                  </div>
                </div>
              </div>
            </header>
            <section className="relative z-50 mt-[100px] flex flex-col items-center px-gutter-mobile lg:px-gutter">
              <div className="w-full max-w-main-content">
                <h1 className="max-w-[792px] text-[40px] font-bold leading-tight lg:text-[96px] lg:leading-[110%]">
                  The NIL platform driven by fans
                </h1>
                <p className="mt-8 max-w-[365px] text-xl font-normal leading-normal text-color-text-paragraph lg:text-2xl">
                  Compete with rival fan bases to land the best talent.
                </p>
                <p className="mt-4 max-w-[365px] text-lg font-normal leading-normal text-color-text-muted lg:text-base">
                  Donate directly to your team and get the entire roster paid, equally.
                </p>
                <Transition
                  show={isIdChecked && !fanId}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="mt-8 lg:mt-14">
                    <button
                      className="button-large-primary"
                      onClick={() => setIsJoinOpen(true)}
                      id="hero-cta"
                    >
                      Join Arena
                    </button>
                  </div>
                  <div className="mt-6 flex items-center lg:mt-10">
                    <div className="text-sm text-color-text-secondary">
                      Already have an account?
                    </div>
                    <button
                      className="ml-4 font-medium text-[#22D3EE]"
                      type="button"
                      onClick={() => setIsLoginlOpen(true)}
                    >
                      Log in
                    </button>
                  </div>
                </Transition>
                <Transition
                  show={isIdChecked && !!fanId}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="mt-8 lg:mt-14">
                    <button
                      className="button-large-primary relative flex items-center justify-center"
                      onClick={() => setIsShareModalOpen(true)}
                    >
                      <FiShare className="mr-4" /> Invite to Arena
                    </button>
                  </div>
                </Transition>
              </div>
            </section>
            <div className="absolute bottom-[-80px] z-60 flex w-full items-center justify-center">
              <Countdown />
            </div>
            <div className="absolute bottom-0 right-0">
              <img src="/images/hero.svg" alt="hero" className="" />
              {/* <img src="/images/hero-large.svg" alt="hero" className="hidden 2xl:block" /> */}
            </div>
          </div>
          <section className="relative z-40 h-[554px] w-full bg-[#A3F7A1] px-gutter-mobile md:px-gutter">
            <div className="relative z-10 mx-auto flex h-full max-w-[784px] flex-col items-center justify-center">
              <h2 className="relative z-10 w-full max-w-main-content pt-10 text-center text-3xl font-bold leading-[120%] text-color-text-inverted-primary md:pt-4 md:text-[40px]">
                Join before <span className="text-[#1149B5]">signing day</span> to give your team
                early access to our new platform
              </h2>
              <div className="my-6">
                <img src="/images/fan-badge.svg" />
              </div>
              {!fanId && (
                <div>
                  <button className="button-large-inverted" onClick={() => setIsJoinOpen(true)}>
                    Join Arena
                  </button>
                </div>
              )}
            </div>
            <div className="absolute top-0 left-0">
              <img src="/images/teal-ellipse-left.svg" />
            </div>
          </section>
          <section className="relative mb-20 flex flex-col items-center bg-color-bg-primary px-gutter-mobile pt-20 lg:z-20 lg:px-gutter">
            <div className="w-full max-w-main-content">
              <h2
                id={LEADERBOARD_ID}
                className="max-w-[640px] text-4xl font-bold md:text-[3rem] md:leading-[5rem] lg:text-[4rem]"
              >
                Leaderboard
              </h2>
              <p className="mt-6 text-xl leading-normal text-color-text-paragraph md:text-2xl">
                Race your rivals to {FAN_COUNT_TEXT} fans.
              </p>
              <div className="mt-14">
                <div>
                  <div className="flex items-center justify-end lg:justify-between">
                    <div className="md:hidden">&nbsp;</div>
                    <div className="hidden space-x-1 lg:block">
                      <button
                        className={classNames(
                          'rounded-t-lg border border-b-0 p-6 font-semibold',
                          activeLeaderboardTab === LeaderboardTabs.Fan
                            ? 'border-[#98F5A9] bg-color-bg-dark-row font-semibold text-brand-blue-400'
                            : 'border-transparent bg-[#1A2337] text-color-text-muted',
                        )}
                        onClick={() => setActiveLeaderboardTab(LeaderboardTabs.Fan)}
                      >
                        Fan Engagement
                      </button>
                      <button
                        className={classNames(
                          'rounded-t-lg border border-b-0 p-6',
                          activeLeaderboardTab === LeaderboardTabs.Bank
                            ? 'border-[#98F5A9] bg-color-bg-dark-row font-semibold text-brand-blue-400'
                            : 'border-transparent bg-[#1A2337] text-color-text-muted',
                        )}
                        onClick={() => setActiveLeaderboardTab(LeaderboardTabs.Bank)}
                      >
                        Team NIL Bank
                      </button>
                    </div>
                    <div className="flex lg:space-x-6">
                      <div className="relative hidden lg:block">
                        <div className="absolute top-3.5 left-4 my-auto">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z"
                              stroke="#9CA3AF"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <input
                          className="input-form w-[240px] pl-12 pr-2"
                          placeholder="Search"
                          value={leaderboardSearch}
                          onChange={(e) => setLeaderboardSearch(e.target.value)}
                        />
                      </div>
                      <button
                        onClick={() => setIsSelectConferenceOpen(true)}
                        className="input-form lg- mb-4 inline-flex w-[240px] items-center justify-between lg:mb-0"
                      >
                        <span className="inline-flex items-center">
                          {!conferenceObject ? (
                            'All Conferences'
                          ) : (
                            <span className="inline-flex items-center">
                              <img src={conferenceObject.logo} className="mr-2 h-[24px]" />{' '}
                              {conferenceObject.displayName}
                            </span>
                          )}
                        </span>
                        <ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <ScrollbarContainer className="h-[68vh] overflow-hidden overflow-x-auto rounded-xl rounded-tl-none border border-[#98F5A9]">
                    {activeLeaderboardTab === LeaderboardTabs.Fan ? (
                      <>
                        <LeaderboardGridRow className="grid min-w-[1000px] bg-color-bg-dark-row ">
                          {leaderboardHeaders.map((header) => (
                            <div
                              key={header}
                              className="h-[44px] py-[13px] pr-0.5 text-center text-xs leading-[1.125rem] text-color-text-muted"
                            >
                              {header}
                            </div>
                          ))}
                        </LeaderboardGridRow>
                        <ScrollbarContainer className="flex h-[calc(100%_-_44px)] min-w-[1000px] shrink-0 grow flex-col overflow-y-auto">
                          {leaderboardTeams.map((team, i) => (
                            <LeaderboardGridRow
                              key={team.name}
                              className="alternating-rows grid h-[80px] shrink-0 items-center text-center"
                            >
                              <div className="text-xl font-bold leading-none">{i + 1}</div>
                              <div className="flex items-center pl-6 text-left leading-none">
                                <img src={team.logo} className="mr-6 w-12" />{' '}
                                <div>
                                  <div className="text-lg font-bold">{team.name}</div>
                                  <div className="text-xs font-normal text-color-text-paragraph">
                                    {team.mascot}
                                  </div>
                                </div>
                              </div>
                              <div className="px-2">
                                <LeaderboardMeter fanCount={team.fans || 1} />
                              </div>
                              <div className="text-xl font-medium">
                                {team.fans ? team.fans.toLocaleString() : '0'}
                              </div>
                              <div className="pl-2 text-center text-xs text-color-text-muted">
                                Coming Soon
                              </div>
                              {/* <div># last hour</div> */}
                            </LeaderboardGridRow>
                          ))}
                        </ScrollbarContainer>
                      </>
                    ) : (
                      <div className="flex h-full w-full flex-col items-center justify-center px-4 text-center">
                        <div>
                          <BiLockOpen className="text-[120px] text-brand-gray-700" />
                        </div>
                        <div className="mt-4 text-2xl font-medium text-color-text-secondary">
                          Donations to your team's NIL bank open Q1 2023
                        </div>
                        <div className="mt-2 text-xl text-color-text-paragraph">
                          Join now to beat your rivals and win access before them
                        </div>
                        {!fanId && (
                          <div className="mt-12 text-center">
                            <button
                              className="button-large-primary"
                              onClick={() => setIsJoinOpen(true)}
                            >
                              Join Arena
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </ScrollbarContainer>
                </div>
                <div className="mx-auto mt-[5rem] w-full max-w-[680px]">
                  <h3 className="bg-gradient-to-r from-[#A3F7A1] to-[#8EDBE0] bg-clip-text text-center text-3xl font-bold leading-[1.2] text-transparent md:text-[2.5rem]">
                    Compete with rival fan bases to land the best talent.
                  </h3>
                  {!fanId && (
                    <div className="mt-12 text-center">
                      <button className="button-large-primary" onClick={() => setIsJoinOpen(true)}>
                        Join Arena
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="relative mt-40 flex flex-col items-center overflow-y-visible px-gutter-mobile pb-28 lg:px-gutter">
            <div className="relative z-10 mx-auto w-full max-w-main-content">
              <div className="flex flex-col lg:flex-row">
                <div>
                  <h2 className="lg:leading max-w-[558px] text-[3rem] text-4xl font-bold leading-[120%] lg:text-[4rem]">
                    Arena isn't just another collective for superstars.
                  </h2>
                  <p className="mt-8 max-w-[486px] text-xl leading-normal text-color-text-paragraph">
                    Arena empowers fans to contribute to their team's Arena NIL fund, which is
                    distributed evenly across every player on the roster. Simple and transparent for
                    fans and athletes.
                  </p>
                  <button
                    type="button"
                    onClick={() => setIsLearnMoreModalOpen(true)}
                    className="mt-4 max-w-[320px] text-left font-medium text-[#22D3EE] sm:max-w-none"
                  >
                    Learn why equal distributions is the future of NIL
                  </button>
                </div>
                <div className="mt-8 flex w-[640px] shrink-0 justify-center lg:mt-0">
                  <img src="/images/players.svg" alt="team" />
                </div>
              </div>
            </div>
            <div className="absolute">
              <img src="/images/logo-bg-1.svg" alt="Arena" />
            </div>
          </section>
          <section className="mt-8 flex flex-col items-center px-gutter-mobile pb-28 lg:mt-20 lg:px-gutter">
            <div className="mx-auto w-full max-w-main-content">
              <div className="w-full">
                <h2 className="text-center text-4xl font-bold leading-[5rem] lg:text-[4rem]">
                  How it works
                </h2>
                <h3 className="bg-gradient-to-r from-[#A3F7A1] to-[#8EDBE0] bg-clip-text text-center text-xl font-bold leading-[1.2] text-transparent md:text-2xl">
                  Attract the best talent to your school by proving your fandom
                </h3>
                <div className="mt-20 flex flex-col items-center justify-between space-y-20 lg:flex-row lg:space-x-4 lg:space-y-0">
                  {howItWorks.map((item, i) => (
                    <div key={item.title} className="text-center">
                      <div className="h-[304px]">
                        <img src={item.image} alt={item.title} />
                      </div>
                      <h3 className="mt-4 text-2xl font-bold leading-[60px] lg:mt-10 lg:text-5xl">
                        {i + 1}. {item.title}
                      </h3>
                      <p
                        className={classNames(
                          'mt-0 text-lg lg:mt-6 lg:text-xl',
                          item.description === LIVE_TEXT
                            ? 'text-shadow-live text-[#75ECFF]'
                            : 'text-color-text-muted',
                        )}
                      >
                        {item.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <section className="flex flex-col items-center px-gutter-mobile pt-20 pb-[152px] lg:px-gutter">
            <div className="w-full max-w-main-content">
              <div>
                <h2 className="max-w-[588px] text-4xl font-bold leading-[120%] lg:text-[4rem]">
                  Why athletes love Arena
                </h2>
              </div>
              <div className="mt-24 flex w-full">
                <div className="grid grid-cols-1 gap-x-16 gap-y-24 lg:grid-cols-2">
                  {athleteValueProps.map((item) => (
                    <div key={item.title} className="flex">
                      <div className="mr-6 h-10 w-10 shrink-0">
                        <img src={item.image} />
                      </div>
                      <div>
                        <h3 className="flex text-[24px] font-bold leading-[130%] lg:text-[40px] lg:leading-[50px]">
                          {item.title}
                        </h3>
                        <p className="mt-4 leading-[150%] text-color-text-muted lg:text-xl">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <section className="relative flex h-[480px] overflow-hidden bg-[#A463FB] px-gutter-mobile lg:px-gutter">
            <div className="relative z-10 flex w-full items-center justify-center">
              <div className="flex flex-col items-center">
                <h3 className="text-center text-5xl font-bold text-color-text-grayscale-primary">
                  Are you an athlete?
                </h3>
                <div className="mt-16">
                  {!fanId ? (
                    <button
                      className="button-large-primary"
                      onClick={() => setIsAthleteJoinOpen(true)}
                    >
                      Join Arena
                    </button>
                  ) : (
                    <button
                      className="button-large-primary relative flex items-center justify-center"
                      onClick={() => setIsShareModalOpen(true)}
                    >
                      <FiShare className="mr-4" /> Invite Team to Arena
                    </button>
                  )}
                </div>
              </div>
              <div className="hidden md:block">
                <img src="/images/athlete-cta.svg" alt="athlete" />
              </div>
            </div>
            <div className="absolute top-0 right-0 hidden md:block">
              <img src="/images/teal-ellipse-right.svg" />
            </div>
          </section>
          <section className="relative mb-20 flex flex-col items-center bg-color-bg-primary px-gutter-mobile pt-[110px] lg:z-20 lg:px-gutter">
            <div className="flex w-full max-w-main-content flex-col lg:flex-row">
              <div className="w-full max-w-[486px]">
                <h2 className="max-w-[486px] text-4xl font-bold lg:text-[4rem]">FAQ</h2>
                <p className="mt-2 max-w-[384px] text-lg leading-normal text-color-text-paragraph lg:mt-8 lg:text-2xl">
                  Everything you need to know about Arena.
                </p>
              </div>
              <div className="mt-8 w-full space-y-10 lg:mt-0">
                {faqs.map((faq, index) => (
                  <Faq title={faq.title} description={faq.description} index={index} key={index} />
                ))}
              </div>
            </div>
          </section>
          <section
            id={FORM_ID}
            className="relative w-full bg-[#A3F7A1] px-gutter-mobile lg:px-gutter"
          >
            <div className="relative z-10 mx-auto w-full max-w-main-content">
              <div className="flex w-full justify-center">
                <div className="mx-auto mt-12 w-full max-w-[588px] lg:mt-0">
                  <ContactForm fanId={fanId} />
                </div>
              </div>
            </div>
            <div className="absolute top-0 left-0">
              <img src="/images/teal-ellipse-left-large.svg" />
            </div>
          </section>
          <footer
            className={classNames(
              'mt-0 flex items-center justify-between px-gutter-mobile pt-gutter md:mt-20 lg:px-gutter',
              'lg:pb-gutter',
            )}
          >
            <div className="flex w-full max-w-main-content flex-col items-center justify-between md:flex-row">
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="mb-8 shrink-0 md:mb-0"
              >
                <img src="/images/logo.svg" className="w-auto" />
              </a>
              <div className="flex flex-col items-end">
                <div className="text-color-text-muted">
                  Athletes, universities, and businesses - we're always available:
                </div>
                <a
                  href="mailto:team@arenanil.io"
                  className="text-lg font-semibold text-color-text-paragraph"
                >
                  team@arenanil.io
                </a>
              </div>
            </div>
          </footer>
          <div className="fixed bottom-0 left-0 z-30 w-screen bg-color-bg-primary bg-opacity-70 px-gutter-mobile backdrop-blur-md lg:px-gutter">
            {isFormSubmitted || viewer ? (
              <>
                <div className="hidden w-full items-center justify-between py-4 lg:flex">
                  <div className="flex items-center">
                    <button
                      className="button-small-primary relative flex items-center justify-center py-4"
                      onClick={() => setIsShareModalOpen(true)}
                    >
                      <FiShare className="mr-4" /> Invite to Arena
                    </button>
                    <div className="ml-6 flex items-center font-bold">
                      {teamObject?.logo && <img src={teamObject.logo} className="mr-3 h-6" />}{' '}
                      <div>{transformFullNameToParts(viewer.name || name || '').firstname}</div>
                    </div>
                    <div className="ml-6 flex items-center space-x-4">
                      <div>Stats:</div>
                      <div className="space-x-2">
                        <span className="text-shadow-live text-[#75ECFF]">
                          {viewer?.clicks || 0}
                        </span>
                        <span className="text-color-text-paragraph">clicks</span>
                      </div>
                      <div className="space-x-2">
                        <span className="text-shadow-live text-[#75ECFF]">
                          {viewer?.signups || 0}
                        </span>
                        <span className="text-color-text-paragraph">sign ups</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span className="mr-2 font-bold">{viewer?.team?.name || teamObject?.name}</span>{' '}
                    <span className="text-shadow-live text-[#75ECFF]">
                      {(leaderboard[viewer?.team?.id || teamObject?.id] || 0) + 1}
                    </span>{' '}
                    <span className="text-color-text-paragraph">total fans</span>
                  </div>
                </div>
                <div className="flex justify-center py-3 lg:hidden">
                  <button
                    className="button-small-primary relative flex w-full items-center justify-center py-4"
                    onClick={() => setIsShareModalOpen(true)}
                  >
                    <FiShare className="absolute right-8 text-lg" /> Invite to Arena
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-center py-3 lg:hidden">
                  <button
                    type="button"
                    className="button-small-primary w-full py-4"
                    onClick={() => setIsJoinOpen(true)}
                  >
                    Join Arena
                  </button>
                </div>
                <div className="max-w-main-conten hidden w-full py-4 lg:block">
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();

                      if (!selectedTeam) {
                        setIsJoinOpen(true);
                      } else {
                        if (isLoading) {
                          return;
                        }

                        setIsLoading(true);

                        let referralUserId;
                        try {
                          referralUserId =
                            router.query.fanId || localStorage.getItem(REF_ID_KEY) || undefined;
                        } catch (error) {
                          Sentry.captureException(error);
                        }

                        try {
                          const response = await submitJoin({
                            teamId: selectedTeam,
                            name,
                            email,
                            isAthlete: false,
                            referralUserId,
                          });
                          setViewer(response);
                          const fanId = response.id;
                          setFanId(fanId);
                          setIsLoading(false);
                          localStorage.setItem(STORED_ID_KEY, fanId);
                        } catch (error) {
                          Sentry.captureException(error);
                        }
                        setIsFormSubmitted(true);
                        setIsSuccessModalOpen(true);
                      }

                      return;
                    }}
                    id="floating-form"
                    className="flex w-full space-x-6"
                  >
                    <div className="w-full">
                      <button
                        type="button"
                        className="input-form inline-flex items-center justify-center"
                        onClick={() => setIsSelectTeamOpen(true)}
                      >
                        {!teamObject ? (
                          'Select Team'
                        ) : (
                          <span className="inline-flex items-center">
                            <img src={teamObject.logo} className="mr-3 h-6" />
                            <span>{teamObject.name}</span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className="w-full">
                      <label
                        className="sr-only font-medium text-color-text-muted"
                        htmlFor="name-footer"
                      >
                        Name <span className="text-xs text-red-500">*</span>
                      </label>
                      <div>
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                          id="name-footer"
                          name="name-footer"
                          autoComplete="name"
                          className="input-form"
                          required
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <label
                        className="sr-only font-medium text-color-text-muted"
                        htmlFor="email-footer"
                      >
                        Email <span className="text-xs text-red-500">*</span>
                      </label>
                      <div>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                          id="email-footer"
                          name="email-footer"
                          autoComplete="email"
                          type="email"
                          className="input-form"
                          required
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                    <button
                      className="button-small-primary shrink-0"
                      type="submit"
                      id="floating-submit"
                      disabled={isLoading}
                    >
                      {isLoading ? <Spinner /> : 'Join Arena'}
                    </button>
                  </form>
                </div>
              </>
            )}
          </div>
        </main>
        <img src="/app/close-icon.svg" style={{ display: 'none' }} />
      </div>
      <ModalSelectTeam
        setTeam={(team) => setSelectedTeam(team)}
        team={selectedTeam}
        isOpen={isSelectTeamOpen}
        handleClose={() => setIsSelectTeamOpen(false)}
        teams={teams}
        conferences={conferences}
      />
      <ModalSelectConference
        isOpen={isSelectConferenceOpen}
        handleClose={() => setIsSelectConferenceOpen(false)}
        conferences={conferences}
        handleSelectConference={(conferenceId) => {
          setSelectedConference(conferenceId);
          setIsSelectConferenceOpen(false);
        }}
      />
      <ModalJoin
        teams={teams}
        setTeam={(team) => setSelectedTeam(team)}
        team={selectedTeam}
        existingName={name}
        existingEmail={email}
        setExistingName={setName}
        setExistingEmail={setEmail}
        isOpen={isJoinOpen}
        handleClose={() => setIsJoinOpen(false)}
        handleSuccess={(response) => {
          const fanId = response.id;
          setFanId(fanId);
          setIsSuccessModalOpen(true);
          setViewer(response);
          try {
            localStorage.setItem(STORED_ID_KEY, fanId);
          } catch (error) {
            Sentry.captureException(error);
          }
        }}
        conferences={conferences}
      />
      <ModalJoin
        isAthlete
        setTeam={(team) => setSelectedTeam(team)}
        team={selectedTeam}
        existingName={name}
        existingEmail={email}
        setExistingName={setName}
        setExistingEmail={setEmail}
        isOpen={isAthleteJoinOpen}
        handleClose={() => setIsAthleteJoinOpen(false)}
        handleSuccess={(response) => {
          const fanId = response.id;
          setFanId(fanId);
          setIsSuccessModalOpen(true);
          setViewer(response);
          try {
            localStorage.setItem(STORED_ID_KEY, fanId);
          } catch (error) {
            Sentry.captureException(error);
          }
        }}
        teams={teams}
        conferences={conferences}
      />
      <ModalSuccess
        isOpen={isSuccessModalOpen}
        handleClose={() => setIsSuccessModalOpen(false)}
        fanId={fanId}
        teamName={teamObject?.name || ''}
        teamMascot={teamObject?.mascot || ''}
        teamLogo={teamObject?.logo || ''}
        username=""
        conferenceSlug={teamObject?.conference?.slug || ''}
      />
      <ModalShare
        isOpen={isShareModalOpen}
        handleClose={() => setIsShareModalOpen(false)}
        fanId={fanId}
        teamName={teamObject?.name || ''}
        teamMascot={teamObject?.mascot || ''}
        teamLogo={teamObject?.logo || ''}
        username=""
        conferenceSlug={teamObject?.conference?.slug || ''}
        teamFanCount={leaderboard[viewer?.team?.id || teamObject?.id] || 0}
        clicks={viewer?.clicks || 0}
        signups={viewer?.signups || 0}
      />
      <ModalLogin
        isOpen={isLoginOpen}
        handleClose={() => setIsLoginlOpen(false)}
        handleLogin={(response) => {
          if (!response.id) {
            toast.error('Account not found. Ensure your email is correct.');
            return;
          }

          const fanId = response.id;
          setFanId(fanId);
          setName(response.name);
          setSelectedTeam(response.teamId);
          setViewer(response);
          setIsFormSubmitted(true);
          setIsLoginlOpen(false);
          try {
            localStorage.setItem(STORED_ID_KEY, fanId);
          } catch (error) {
            Sentry.captureException(error);
          }
        }}
      />
      <ModalLearnMore
        isOpen={isLearnMoreModalOpen}
        handleClose={() => setIsLearnMoreModalOpen(false)}
      />
    </>
  );
}
