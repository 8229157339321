export const UNIX_SIGNING_DAY_END = 1671685140000;
const TIME_IN_HOURS = UNIX_SIGNING_DAY_END - Date.now();

function prependZero(str: string) {
  return str.length > 1 ? str : '0' + str;
}

export const getCountDown = (initDate) => {
  // Get todays date and time
  var now = new Date().getTime();

  // Find the distance between now an the count down date
  var distance = initDate - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24)) + '';
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) + '';
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) + '';
  var seconds = Math.floor((distance % (1000 * 60)) / 1000) + '';
  var ms = Math.floor(distance % 1000) + '';

  // Display the result in the element with id="demo"
  return {
    days: prependZero(days),
    hours: prependZero(hours),
    minutes: prependZero(minutes),
    seconds: prependZero(seconds),
    ms: prependZero(ms.slice(0, 1)),
  };
};

export const getCountdownToSigningDay = () => {
  return getCountDown(UNIX_SIGNING_DAY_END);
};

/*var TIME_IN_HOURS = 3.5;
  var CLOCK_ID = 'countdown-clock';

  function getCountDown(initDate) {
   // Get todays date and time
    var now = new Date().getTime();

    // Find the distance between now an the count down date
    var distance = initDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    var ms = Math.floor((distance % (1000)));

    // Display the result in the element with id="demo"
    return days + "d " + hours + "h "
    + minutes + "m " + seconds + "s " + ms + "ms ";
  }
  // Set the date we're counting down to

  var countDownDate = Date.now() + TIME_IN_HOURS * 60 * 60 * 1000;

  var interval = setInterval(() => {
    if (Date.now() >= countDownDate) {
         document.getElementById(CLOCK_ID).innerHTML = "0d 0h 0m 0s 0ms";
         clearInterval(interval)
         return;
    }

    var strCounDown = getCountDown(countDownDate)
    document.getElementById(CLOCK_ID).innerHTML = strCounDown;
  }, 100);*/
